<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="课程类型" prop="course_type">
        <el-radio-group v-model="info.course_type" :disabled="info.id > 0">
          <el-radio :label="1">单课</el-radio>
          <el-radio :label="2">系列课</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="课程名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入课程名称"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="menu_id">
        <el-select v-model="info.menu_id" placeholder="请选择分类">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="讲师">
        <el-select v-model="info.teacher_id" placeholder="请选择讲师" clearable>
          <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="imgChoose"></SelectImgs>
      </el-form-item>
      <el-form-item label="选择视频" prop="url" v-if="info.course_type==1">
        <el-button type="primary" @click="showVideoModel=true;videoIdx=1">选择视频</el-button>
        <span class="mg-lf-10">{{info.video_title}}</span>
      </el-form-item>
      <el-form-item label="限制VIP" prop="is_vip">
        <el-switch
            v-model="info.is_vip"
            :active-value="1"
            :inactive-value="0"
            active-text=""
            inactive-text="">
        </el-switch>
      </el-form-item>
      <el-form-item label="选择试看视频" prop="url" v-if="info.is_vip==1 && info.course_type==1">
        <el-button type="primary" @click="showVideoModel=true;videoIdx=2">选择视频</el-button>
        <span class="mg-lf-10">{{info.free_video_title}}</span>
      </el-form-item>
      <el-form-item label="章节" v-if="info.course_type==2">
        <draggable v-model="info.item" :options="traggabelOptions" :group="{ name: 'test', pull: true, put: false }">
        <div class="c-item" v-for="(item,index) in info.item" :key="index">
          <el-input v-model="item.title" placeholder="请输入章节名称">
            <el-button slot="append" @click="delItem(index)">删除</el-button>
          </el-input>
          <el-row class="mg-tp-10">
            <el-col :span="12">
              <el-button type="primary" @click="showVideoModel=true;videoFreeType=1;videoIdx=index">选择视频</el-button>
              <span class="mg-lf-10">{{item.video_title}}</span>
            </el-col>
            <el-col :span="12" v-if="info.is_vip==1">
              <el-button type="primary" @click="showVideoModel=true;videoFreeType=2;videoIdx=index">选择试看视频</el-button>
              <span class="mg-lf-10">{{item.free_video_title}}</span>
            </el-col>
          </el-row>
        </div>
        </draggable>
        <el-button type="warning" @click="addItem()" class="mg-tp-10">新增章节</el-button>
      </el-form-item>
      <el-form-item label="评分">
        <el-input v-model="info.score" placeholder="请输入评分">
          <template slot="append">0~10分，例如8.4</template>
        </el-input>
      </el-form-item>
      <el-form-item label="热度">
        <el-input v-model="info.hot" placeholder="请输入热度"></el-input>
      </el-form-item>
      <el-form-item label="标签">
        <el-input placeholder="请输入内容" v-model="tagName" class="input-with-select" style="width: 180px">
          <el-button slot="append" @click="addTag">添加</el-button>
        </el-input>
        <div class="tags">
          <div class="item" v-for="(item,index) in info.label" :key="index">
            <el-input placeholder="请输入内容" v-model="info.label[index]" class="input-with-select" style="width: 180px">
              <el-button slot="append" @click="delTag(index)">删除</el-button>
            </el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="发布时间" prop="release_time">
        <el-date-picker type="date" v-model="info.release_time" placeholder="请选择发布时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="详情" prop="content">
        <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="选择商品" :visible.sync="showModel" custom-class="dialog">
      <goods-list :type="2" :goodsType="1" :selectFunc="chooseGoods"></goods-list>
    </el-dialog>
    <el-dialog title="选择视频" :visible.sync="showVideoModel" custom-class="dialog">
      <video-list :type="1" :selectFunc="chooseVideo"></video-list>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import GoodsList from "@/components/GoodsList";
import VideoList from "@/components/VideoList";
import WangEdit from "../../components/WangEdit";
import draggable from 'vuedraggable';
export default {
  data() {
    return {
      showModel: false,
      showVideoModel: false,
      tableHeader: [],
      tableData: [],
      menuList: [],
      teacherList: [],
      tableRandKey: 1,
      tagName: '',
      videoIdx: '',
      videoFreeType: '',
      info: {
        id: 0,
        menu_id: '',
        course_type: 1,
        img: '',
        intro: '',
        content: '',
        teacher_id: '',
        label: [],
        release_time: '',
        free_video_id: '',
        video_id: '',
        is_vip: 0,
        item: []
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        course_type: [
          {required: true, message: '请选择类型', trigger: 'blur'}
        ],
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        menu_id: [
          {required: true, message: '请选择分类', trigger: 'blur'}
        ],
        release_time: [
          {required: true, message: '请选择发布时间', trigger: 'blur'}
        ]
      },
      traggabelOptions: {
        // group: {
        //   name: 'bottomNav',
        //   pull: true,
        //   put: true
        // },
        sort: true,
        animation: 100
      }
    };
  },
  created() {
    this.getMenuList()
    this.getTeacherList()
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    SelectImgs,
    GoodsList,
    WangEdit,
    VideoList,
    draggable
  },
  computed: {},
  methods: {
    setText(txt) {
      this.info.content = txt
    },
    addTag() {
      if(this.tagName=='') {
        this.fail('请输入标签名')
      } else {
        this.info.label.push(this.tagName)
        this.tagName = ''
      }
    },
    delTag(index) {
      this.info.label.splice(index,1)
    },
    imgChoose(item) {
      var _this = this
      this.info.img = item.pic
    },
    chooseGoods(goods) {
      goods.forEach(item => {
        this.info.goods.push(item)
      })
      this.showModel = false
    },
    delGoods(index) {
      this.info.goods.splice(index,1)
    },
    chooseVideo(item) {
      this.showVideoModel = false
      if(this.info.course_type==2) {
        if(this.videoFreeType==1) {
          this.info.item[this.videoIdx].video_id = item.id
          this.info.item[this.videoIdx].video_title = item.title
        } else {
          this.info.item[this.videoIdx].free_video_id = item.id
          this.info.item[this.videoIdx].free_video_title = item.title
        }
      } else {
        if(this.videoIdx==1) {
          this.info.video_id = item.id
          this.info.video_title = item.title
        } else {
          this.info.free_video_id = item.id
          this.info.free_video_title = item.title
        }
      }
    },
    getMenuList() {
      this.$api.course.courseMenuIndex({page_size:100}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getTeacherList() {
      this.$api.course.courseTeacherIndex({page_size:100}, res => {
        if (res.errcode == 0) {
          this.teacherList = res.data.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.course.courseDetail({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addItem() {
      this.info.item.push({
        title: '',
        free_video_id: '',
        free_video_title: '',
        video_id: '',
        video_title: '',
        is_vip: ''
      })
    },
    delItem(index) {
      this.info.item.splice(index,1)
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(this.info))
          this.$api.course.courseEdit(param, function (res) {
            if (res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.tags .item {
  margin-right: 10px;
  margin-bottom: 10px;
}
.c-item {
  background-color: #f5f5f5;
  border: 1px solid #eee;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
